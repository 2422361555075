import * as React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Rating from "@mui/material/Rating";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import AppNavBar from "../../components/navbar";
import Footer from "../../components/footer";
import backgroundImage from "../../images/dog-products.jpg";
import Food from "../../images/Food.jpg";
import Accessory from "../../images/Accessory.jpg";
import Furniture from "../../images/Furniture.jpg";
import Grooming from "../../images/Grooming.jpg";
import Health from "../../images/Health.jpg";

const theme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#00C1EB",
    },
    secondary: {
      main: "#00C1EB",
      contrastText: "#00C1EB",
      dark: "#000000",
    },
    background: {
      default: "#ffffff",
    },
    divider: "rgba(0,0,0,0.12)",
    info: {
      main: "#f44336",
    },
  },
});

export default function MediaCard() {
  const userToken = JSON.parse(localStorage.getItem("userToken"));
  const [filter, setFilter] = useState();
  const [products, setProducts] = useState([]);
  const [cartItems, setCartItems] = useState(
    JSON.parse(localStorage.getItem("cartItems")) || []
  );

  const handleFilter = (filterType) => {
    setFilter(filterType);
  };

  const addToCart = (product) => {
    const existingItem = cartItems.find(
      (item) => item.product_ID === product.product_ID
    );
    if (existingItem) {
      existingItem.quantity += 1;
    } else {
      const newItem = { ...product, quantity: 1 };
      setCartItems([...cartItems, newItem]);
    }

    localStorage.setItem("cartItems", JSON.stringify(cartItems));
  };

  useEffect(() => {
    const fetchProducts = async () => {
      const data = await fetch("https://api.animal-aid.ca/api/product");
      const jsonData = await data.json();
      setProducts(Object.values(jsonData));
    };
    fetchProducts();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <AppNavBar />
      <Box
        sx={{
          bgcolor: "#cfe8fc",
          position: "relative",
          height: "20vh",
          backgroundImage: `url(${backgroundImage})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          borderBottomRightRadius: 10,
          opacity: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 1,
        }}
      >
        <Container maxWidth="xl">
          <Typography
            variant="h1"
            textAlign="center"
            sx={{ color: "rgba(0, 0, 0, 0.7)" }}
          >
            Products
          </Typography>
        </Container>
      </Box>

      <Box sx={{ display: "flex", flexDirection: "row", marginBottom: "5%" }}>
        <Box
          sx={{
            bgcolor: "#cfe8fc",
            height: "100vh",
            width: "10vw",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginRight: "5%",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
          }}
        >
          <Typography sx={{ top: "50%" }} variant="h4" gutterBottom>
            Filter by:
          </Typography>
          <Box sx={{ my: 2 }}>
            <Button variant="outlined" onClick={() => handleFilter("")}>
              All Products
            </Button>
          </Box>
          <Box sx={{ my: 2 }}>
            <Button variant="outlined" onClick={() => handleFilter("Food")}>
              Food
            </Button>
          </Box>
          <Box sx={{ my: 2 }}>
            <Button
              variant="outlined"
              onClick={() => handleFilter("Accessory")}
            >
              Accessory
            </Button>
          </Box>
          <Box sx={{ my: 2 }}>
            <Button
              variant="outlined"
              onClick={() => handleFilter("Furniture")}
            >
              Furniture
            </Button>
          </Box>
          <Box sx={{ my: 2 }}>
            <Button variant="outlined" onClick={() => handleFilter("Grooming")}>
              Grooming
            </Button>
          </Box>
          <Box sx={{ my: 2 }}>
            <Button variant="outlined" onClick={() => handleFilter("Health")}>
              Health
            </Button>
          </Box>
        </Box>

        <Box
          sx={{
            flexGrow: 1,
            paddingTop: "65px",
            position: "relative",
            marginRight: "3%",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            {products.filter((product) =>
              filter ? product.product_type === filter : true
            ).length === 0 ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "50vh",
                }}
              >
                <Typography variant="h4">
                  {" "}
                  No {filter} products available
                </Typography>
              </Box>
            ) : (
              <Grid
                container
                spacing={5}
                minHeight={160}
                sx={{ alignItems: "center" }}
              >
                {products
                  .filter(
                    (product) =>
                      (filter ? product.product_type === filter : true) &&
                      product.price >= 1
                  )
                  .map((product) => (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={3}
                      key={product.product_ID}
                      display="flex"
                      justifyContent="start"
                      alignItems="start"
                    >
                      <Card
                        sx={{
                          borderRadius: 10,
                          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
                          width: "100%",
                        }}
                      >
                        {product.product_type === "Food" ? (
                          <CardMedia
                            sx={{ height: 200, objectFit: "contain" }}
                            image={Food}
                            title={`${product.product_type} cover`}
                          />
                        ) : product.product_type === "Accessory" ? (
                          <CardMedia
                            sx={{ height: 200, objectFit: "cover" }}
                            image={Accessory}
                            title={`${product.product_type} cover`}
                          />
                        ) : product.product_type === "Furniture" ? (
                          <CardMedia
                            sx={{ height: 200 }}
                            image={Furniture}
                            title={`${product.product_type} cover`}
                          />
                        ) : product.product_type === "Grooming" ? (
                          <CardMedia
                            sx={{ height: 200 }}
                            image={Grooming}
                            title={`${product.product_type} cover`}
                          />
                        ) : product.product_type === "Health" ? (
                          <CardMedia
                            sx={{ height: 200 }}
                            image={Health}
                            title={`${product.product_type} cover`}
                          />
                        ) : (
                          <CardContent>No image available</CardContent>
                        )}
                        <CardContent>
                          <Typography
                            gutterBottom
                            variant="h5"
                            component="div"
                            textAlign="left"
                          >
                            {product.product_name}
                          </Typography>
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Rating
                              name="no-value"
                              defaultValue={5}
                              size="small"
                            />
                          </Box>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            textAlign="left"
                            py={1}
                          >
                            {product.description}
                          </Typography>
                          <Typography
                            variant="h6"
                            color="text.secondary"
                            textAlign="left"
                          >
                            ${product.price}
                          </Typography>
                        </CardContent>

                        <CardActions style={{ justifyContent: "center" }}>
                          <Button
                            size="small"
                            component={Link}
                            to={`/proddetails/${product.product_ID}`}
                          >
                            Learn More
                          </Button>

                          <Box mx={1} />

                          {userToken && userToken.email ? (
                            <Button
                              onClick={() => addToCart(product)}
                              size="small"
                            >
                              Add to Cart
                            </Button>
                          ) : (
                            <Typography></Typography>
                          )}
                        </CardActions>
                      </Card>
                    </Grid>
                  ))}
              </Grid>
            )}
          </Box>
        </Box>
      </Box>
      <Footer />
    </ThemeProvider>
  );
}
