import React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import AppNavBar from "../../components/navbar";
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';



function MadeWithLove(props) {
  
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="/">
        AnimalAid P.S
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#00C1EB",
    },
    secondary: {
      main: "#00C1EB",
      contrastText: "#00C1EB",
      dark: "#000000",
    },
    background: {
      default: "#ffffff",
    },
    divider: "rgba(0,0,0,0.12)",
    info: {
      main: "#f44336",
    },
  },
});

export default function SignUp() {
  const navigate = useNavigate();

  const initialValues = {email: "", first_name: "", last_name: "", description: "", profile_image: "", password: "" };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  
  
  const handleChange = (e) => {
    const {name, value} = e.target;
    setFormValues ({...formValues, [name]: value})
  }
    
  const handleSubmit = (e) => {
    const data = new FormData(e.currentTarget);
    const email = data.get("email");
    e.preventDefault();
    fetch(`https://api.animal-aid.ca/api/person`, {
      method: "POST",
      headers: {
        "Content-Type" : "application/json"
      },
      body : JSON.stringify(formValues)
    })
    .then(response=>response.json())
    setIsSubmit(true);
    const userToken = JSON.stringify({ email });
    localStorage.setItem("userToken", userToken);
    navigate("/"); //comment out when testing form
  }

  useEffect(()=> {
    if(Object.keys(formErrors).length === 0 && isSubmit) {
      console.log(formValues);
    }
  },[formErrors, formValues, isSubmit])


  return (
    <ThemeProvider theme={theme}>
      <AppNavBar />

      <Grid
        container
        component="main"
        sx={{ height: "82vh", marginTop: "5vh"  }}
      >
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: "url(https://source.unsplash.com/random/?animal)",
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 6,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>

            {/* Grey Colored Lock Icon */}
            {/* <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar> */}

            <Typography component="h1" variant="h5">
              Sign up
            </Typography>

            <Box
              component="form"
              method="POST"
              action="/"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 1 }}
              style={{ marginTop: "30px" }}
            >
          <Grid container spacing={2}>
              <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    value={formValues.email}
                    onChange={handleChange}
                    autoComplete="email"
                  />
                </Grid>
            
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="first_name"
                    label="First Name"
                    name="first_name"
                    value={formValues.first_name}
                    onChange={handleChange}
                    autoComplete="first_name"
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="last_name"
                    label="Last Name"
                    name="last_name"
                    value={formValues.last_name}
                    onChange={handleChange}
                    autoComplete="last_name"
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    value={formValues.password}
                    onChange={handleChange}
                    id="password"
                    autoComplete="password"
                  />
                </Grid>


                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox value="allowExtraEmails" color="primary" />
                    }
                    label="I want to receive inspiration, marketing promotions and updates via email."
                  />
                </Grid>
              </Grid>

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                style={{ margin: "20px 0px 15px" }}
              >
                Sign Up
              </Button>

              <Grid container justify="flex-center">
                <Grid item>
                  <Link
                    href="/signin"
                    variant="body2"
                    style={{ textDecoration: "none" }}
                  >
                    Already have an account? Sign in
                  </Link>
                </Grid>
              </Grid>

              <MadeWithLove sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}