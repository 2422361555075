import { BrowserRouter, Routes, Route } from "react-router-dom";
import Homepage from "./api/pages/home-Page";
import SignInSide from "./api/forms/signIn-Side";
import SignUpSide from "./api/forms/signUp-Side";
import Products from "./api/pages/productLister-Page";
import ProductDetails from "./api/pages/prodDetails-Page"
import Pets from "./api/pages/petLister-Page" 
import PetDetails from "./api/pages/petDetails-Page" 
import UserProfile from "./api/pages/userProfile" 
import UpdateProfile from "./api/pages/updateProfile"
import FAQ from "./api/pages/FAQ" 
import Test from "../src/components/test-Page";
import AddPets from "./api/forms/addPets"
import AddProducts from "./api/forms/addProducts"
import ShoppingCart from "./api/pages/shoppingCart-Page"
import "./css/app.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

const theme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#00C1EB",
    },
    secondary: {
      main: "#00C1EB",
      contrastText: "#00C1EB",
      dark: "#000000",
    },
    background: {
      default: "#ffffff",
    },
    divider: "rgba(0,0,0,0.12)",
    info: {
      main: "#f44336",
    },
  },
});

function App() {
  const userToken2 = JSON.parse(localStorage.getItem('userToken'));
  if (userToken2) {
    const email = userToken2.email;
  }

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
      <CssBaseline />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Homepage />}></Route>
            <Route path="/FAQ" element={<FAQ />}></Route>
            <Route path={`/person`} element={<UserProfile />}></Route>
            <Route path={`/update/person`} element={<UpdateProfile />}> </Route>
            <Route path="/addpets" element={<AddPets />}></Route>
            <Route path="/addproducts" element={<AddProducts />}></Route>
            <Route path="/signin" element={<SignInSide />}></Route>
            <Route path="/signup" element={<SignUpSide />}></Route>
            <Route path="/products" element={<Products />}></Route>
            <Route path="/proddetails/:id" element={<ProductDetails />}></Route>
            <Route path="/pets" element={<Pets />}></Route>
            <Route path="/petdetails/:id" element={<PetDetails />}></Route>
            <Route path="/cart" element={<ShoppingCart />}></Route>
            <Route path="test" element={<Test />}></Route>
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
}

export default App;
