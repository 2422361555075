import React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import PetsIcon from "@mui/icons-material/Pets";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import MenuItem from "@mui/material/MenuItem";
import AppNavBar from "../../components/navbar";
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';

const theme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#00C1EB",
    },
    secondary: {
      main: "#00C1EB",
      contrastText: "#00C1EB",
      dark: "#000000",
    },
    background: {
      default: "#ffffff",
    },
    divider: "rgba(0,0,0,0.12)",
    info: {
      main: "#f44336",
    },
  },
});

export default function SignUp() {

  const userToken = localStorage.getItem("userToken");
  const userToken2 = JSON.parse(localStorage.getItem("userToken"));

  let emailVar; 
  if (userToken2 && userToken2.email) {
    emailVar = userToken2.email;
  }

  const navigate = useNavigate();

  const species = [
    {
      value: "Dog",
      label: "Dog",
    },
    {
      value: "Cat",
      label: "Cat",
    },
    {
      value: "Rabbit",
      label: "Rabbit",
    },
    {
      value: "Lizard",
      label: "Lizard",
    },
    {
      value: "Hamster",
      label: "Hamster",
    },
  ];

  console.log(`EmailVar:` + emailVar)
  const initialValues = {
    name: "",
    parent: emailVar,
    age: 0,
    species: "",
    breed: "",
    image: "",
    description: "",
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetch(`https://api.animal-aid.ca/api/pet`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formValues),
    }).then((response) => response.json());
    setIsSubmit(true);
    navigate("/"); //comment out when testing form
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      console.log(formValues);
    }
  }, [formErrors, formValues, isSubmit]);

  return (
    <ThemeProvider theme={theme}>
      <AppNavBar />

      <Grid
        container
        component="main"
        sx={{ height: "100vh", marginTop: "5vh" }}
      >
        <CssBaseline />
        <Grid component={Paper} square>
          <Box
            sx={{
              my: 7,
              mx: 50,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <PetsIcon />
            </Avatar>

            {/* Grey Colored Lock Icon */}
            {/* <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar> */}

            <Typography component="h1" variant="h5">
              Add a Pet
            </Typography>
            <Typography component="body">
              Place your pet up for adoption with this form.
            </Typography>

            <Box
              component="form"
              method="POST"
              action="/"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 1 }}
              style={{ marginTop: "20px" }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={7}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="name"
                    label="Pet's Name"
                    name="name"
                    value={formValues.name}
                    onChange={handleChange}
                    autoComplete="email"
                  />
                </Grid>

                {!userToken && (
                  <Grid item xs={12} sm={3}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="parent"
                      label="Relinquisher"
                      name="parent"
                      value={formValues.parent}
                      onChange={handleChange}
                      autoComplete="parent"
                    />
                  </Grid>
                )}

                {userToken && userToken.length > 0 && (
                  <Grid item xs={12} sm={3}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="parent"
                      label="Relinquisher"
                      name="parent"
                      value={formValues.parent}
                      onChange={handleChange}
                      autoComplete="parent"
                      InputProps={{
                        readOnly: true,
                      }}
                      style={{ backgroundColor: "#e6e6e6" }}
                    />
                  </Grid>
                )}

                <Grid item xs={12} sm={2}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="age"
                    label="Pet's Age"
                    name="age"
                    value={formValues.age}
                    onChange={handleChange}
                    autoComplete="age"
                  />
                </Grid>

                <Grid item xs={5}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    type="file"
                    id="image"
                    name="image"
                    value={formValues.image}
                    onChange={handleChange}
                    autoComplete="image"
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <TextField
                    variant="outlined"
                    select
                    required
                    fullWidth
                    id="species"
                    label="Pet Species"
                    name="species"
                    value={formValues.species}
                    onChange={handleChange}
                    autoComplete="species"
                  >
                    {species.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item xs={3}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="breed"
                    label="Pet Breed"
                    name="breed"
                    value={formValues.breed}
                    onChange={handleChange}
                    autoComplete="breed"
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    multiline
                    rows={4}
                    name="description"
                    label="Describe Your Pet in Detail"
                    type="description"
                    id="description"
                    value={formValues.description}
                    onChange={handleChange}
                    autoComplete="current-password"
                  />
                </Grid>
              </Grid>

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                style={{ margin: "20px 0px 15px" }}
              >
                Upload Pet
              </Button>

              <Grid container justify="flex-center">
                <Grid item>
                  <Typography>Review can take up to Two Weeks.</Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
