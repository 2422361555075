import * as React from "react";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { Button, Typography, Container, Box } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import AppNavBar from "../../components/navbar";
import "../../css/images.css";
import Dog from "../../images/Bella.jpg";
import Cat from "../../images/Cat.jpg";
import Rabbit from "../../images/Rabbit.jpg";
import Lizard from "../../images/Lizard.jpg";
import Hamster from "../../images/Hamster.jpg";

const theme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#00C1EB",
    },
    secondary: {
      main: "#00C1EB",
      contrastText: "#00C1EB",
      dark: "#000000",
    },
    background: {
      default: "#ffffff",
    },
    divider: "rgba(0,0,0,0.12)",
    info: {
      main: "#f44336",
    },
  },
});

export default function MediaCard() {
  const [isDeleting, setIsDeleting] = useState(false);
  const [petData, setPetData] = useState([]);
  const navigate = useNavigate();
  const { id } = useParams();
  const userToken = JSON.parse(localStorage.getItem("userToken"));

  const handleGoBackClick = () => {
    navigate("/pets");
  };

  useEffect(() => {
    const fetchPetDetails = async () => {
      const data = await fetch(
        `https://api.animal-aid.ca/api/pet?animal_ID=${id}`
      );
      const jsonData = await data.json();
      setPetData(Object.values(jsonData));
    };
    fetchPetDetails();
  }, [id]);

  const handleDeleteClick = async () => {
    setIsDeleting(true);

    try {
      const response = await fetch(
        `https://api.animal-aid.ca/api/pet?animal_ID=${id}`,
        {
          method: "DELETE",
        }
      );

      if (response.ok) {
        const message = "Pet has been deleted";
        alert(message);
        window.location.href = "/pets";
      } else {
        const { error } = await response.json();
        throw new Error(error);
      }
    } catch (error) {
      alert(`Failed to delete pet: ${error.message}`);
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <AppNavBar />

      {petData[0] && (
        <div key={petData[0].animal_ID}>
          <ArrowBackIcon
            variant="contained"
            onClick={handleGoBackClick}
            style={{
              marginLeft: "3%",
              float: "left",
              cursor: "pointer",
            }}
            fontSize="large"
          />
          <Box
            sx={{
              display: "flex",
              flexGrow: 1,
              paddingTop: "75px",
              marginLeft: "10%",
              marginTop: 10,
            }}
          >
            <Container maxWidth="sm" sx={{ position: "relative" }}>
              <Box
                sx={{
                  bgcolor: "brown",
                  height: "49vh",
                  width: "50vh",
                  maxWidth: "50vh",
                  borderRadius: 10,
                  position: "absolute",
                  left: "2%",
                  top: "5%",
                  zIndex: 0,
                }}
              />
              <Box
                sx={{
                  backgroundImage: `url(${
                    petData[0].species === "Dog"
                      ? Dog
                      : petData[0].species === "Cat"
                      ? Cat
                      : petData[0].species === "Rabbit"
                      ? Rabbit
                      : petData[0].species === "Hamster"
                      ? Hamster
                      : petData[0].species === "Lizard"
                      ? Lizard
                      : petData[0].image
                  })`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundColor: "#cfe8fc",
                  height: "50vh",
                  width: "50vh",
                  maxWidth: "50vh",
                  borderRadius: 10,
                  position: "absolute",
                  zIndex: 1,
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "start",
                }}
              ></Box>
            </Container>

            <Container sx={{ position: "relative" }}>
              <Box sx={{ textAlign: "left" }}>
                <Typography
                  variant="h5"
                  fontWeight={"bold"}
                  sx={{ color: "red", textDecoration: "underline" }}
                >
                  {petData[0].name}
                </Typography>
              </Box>

              <Box
                sx={{
                  float: "left",
                  textAlign: "left",
                  paddingTop: "30px",
                  marginRight: 20,
                }}
              >
                <Typography variant="h6" fontWeight={"bold"}>
                  Description
                </Typography>

                <Typography variant="body1" sx={{ marginTop: 1 }} gutterBottom>
                  {petData[0].description}
                </Typography>

                <Box sx={{ display: "flex", marginTop: 5 }}>
                  <Box sx={{ display: "inline-block" }}>
                    <Typography variant="h6" fontWeight={"bold"}>
                      Found By:
                    </Typography>

                    <Typography variant="body1" sx={{textDecoration: "underline"}} gutterBottom>
                      {petData[0].parent}
                    </Typography>

                    <Typography
                      variant="h6"
                      fontWeight={"bold"}
                      sx={{ marginTop: 2 }}
                    >
                      Breed:
                    </Typography>

                    <Typography variant="body1" gutterBottom>
                      {petData[0].breed}
                    </Typography>
                  </Box>

                  <Box sx={{ display: "inline-block", paddingLeft: "100px" }}>
                    <Typography variant="h6" fontWeight={"bold"}>
                      Pet Species:
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      {petData[0].species}
                    </Typography>

                    <Typography
                      variant="h6"
                      fontWeight={"bold"}
                      sx={{ marginTop: 2 }}
                    >
                      Contact Number:
                    </Typography>

                    <Typography variant="body1" gutterBottom>
                      <a href="tel:416-356-3454">+1 (416)356-3454</a>
                    </Typography>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginTop: "50px",
                  }}
                >
                  <Button
                    variant="contained"
                    onClick={() => {
                      window.location.href = `mailto:${petData[0].parent}`;
                    }}
                  >
                    Contact Owner
                  </Button>
                  {userToken && userToken.email ? (
                    userToken.email === petData[0].parent ? (
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={handleDeleteClick}
                        disabled={isDeleting}
                      >
                        {isDeleting ? "Deleting..." : "Delete Pet"}
                      </Button>
                    ) : (
                      <Typography></Typography>
                    )
                  ) : null}
                </Box>
              </Box>
            </Container>
          </Box>
        </div>
      )}
    </ThemeProvider>
  );
}
