import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Pets";
import { useLocation } from "react-router-dom";
import CartIcon from "./cartIcon";

const appNavBar = () => {
  const location = useLocation();

  const userToken = localStorage.getItem("userToken");
  const userToken2 = JSON.parse(localStorage.getItem("userToken"));

  function logout() {
    localStorage.removeItem("userToken");
  }

  return (
    <AppBar position="static" sx={{ position: "relative", zIndex: 1 }}>
      <Toolbar>
        <IconButton
          href="/"
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
        >
          <MenuIcon />
        </IconButton>
        <Typography
          variant="h6"
          component="div"
          sx={{ flexGrow: 1 }}
        ></Typography>
        {!userToken && location.pathname !== "/signin" && (
          <Button color="inherit" href="/signin">
            Login
          </Button>
        )}
        {!userToken && location.pathname !== "/signup" && (
          <Button color="inherit" href="/signup">
            Signup
          </Button>
        )}

        {/*Showing icon if user is Logged in*/}
        {userToken && userToken.length > 0 && <CartIcon />}

        {userToken && userToken.length > 0 && (
          <Button color="inherit" href={`/person?email=${userToken2.email}`}>
            View Profile
          </Button>
        )}
        {userToken && userToken.length > 0 && (
          <Button
            color="inherit"
            href={`/update/person?email=${userToken2.email}`}
          >
            Edit Profile
          </Button>
        )}
        {userToken && userToken.length > 0 && (
          <Button color="inherit" href="/" onClick={logout}>
            Logout
          </Button>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default appNavBar;
