import * as React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import AppNavBar from "../../components/navbar";
import Footer from "../../components/footer";
import backgroundImage from "../../images/dog-cover.png";
import Dog from "../../images/Bella.jpg";
import Cat from "../../images/Cat.jpg";
import Rabbit from "../../images/Rabbit.jpg";
import Lizard from "../../images/Lizard.jpg";
import Hamster from "../../images/Hamster.jpg";

const theme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#00C1EB",
    },
    secondary: {
      main: "#00C1EB",
      contrastText: "#00C1EB",
      dark: "#000000",
    },
    background: {
      default: "#ffffff",
    },
    divider: "rgba(0,0,0,0.12)",
    info: {
      main: "#f44336",
    },
  },
});

export default function MediaCard() {
  const [filter, setFilter] = useState();
  const [pets, setPets] = useState([]);

  const handleFilter = (filterType) => {
    setFilter(filterType);
  };

  useEffect(() => {
    const fetchPets = async () => {
      const data = await fetch(`https://api.animal-aid.ca/api/pet`);
      const jsonData = await data.json();
      setPets(Object.values(jsonData));
    };
    fetchPets();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <AppNavBar />
      <Box
        sx={{
          bgcolor: "#cfe8fc",
          position: "relative",
          height: "20vh",
          backgroundImage: `url(${backgroundImage})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          borderBottomRightRadius: 10,
          opacity: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 1,
        }}
      >
        <Container maxWidth="xl">
          <Typography
            variant="h1"
            textAlign="center"
            sx={{ color: "rgba(0, 0, 0, 0.7)" }}
          >
            Pets
          </Typography>
        </Container>
      </Box>

      <Box sx={{ display: "flex", flexDirection: "row", marginBottom: "5%" }}>
        <Box
          sx={{
            bgcolor: "#cfe8fc",
            height: "100vh",
            width: "10vw",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginRight: "5%",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
          }}
        >
          <Typography sx={{ top: "50%" }} variant="h4" gutterBottom>
            Filter by:
          </Typography>
          <Box sx={{ my: 2 }}>
            <Button variant="outlined" onClick={() => handleFilter("")}>
              All Pets
            </Button>
          </Box>
          <Box sx={{ my: 2 }}>
            <Button variant="outlined" onClick={() => handleFilter("Dog")}>
              Dogs
            </Button>
          </Box>
          <Box sx={{ my: 2 }}>
            <Button variant="outlined" onClick={() => handleFilter("Cat")}>
              Cats
            </Button>
          </Box>
          <Box sx={{ my: 2 }}>
            <Button variant="outlined" onClick={() => handleFilter("Rabbit")}>
              Rabbits
            </Button>
          </Box>
          <Box sx={{ my: 2 }}>
            <Button variant="outlined" onClick={() => handleFilter("Lizard")}>
              Lizards
            </Button>
          </Box>
          <Box sx={{ my: 2 }}>
            <Button variant="outlined" onClick={() => handleFilter("Hamster")}>
              Hamsters
            </Button>
          </Box>
        </Box>

        <Box
          sx={{
            flexGrow: 1,
            paddingTop: "65px",
            position: "relative",
            marginRight: "3%",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            {pets.filter((pet) => (filter ? pet.species === filter : true) && pet.animal_ID != "null")
              .length === 0 ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "50vh",
                }}
              >
                <Typography variant="h4">No {filter} available</Typography>
              </Box>
            ) : (
              <Grid
                container
                spacing={5}
                minHeight={160}
                sx={{ alignItems: "center" }}
              >
                {pets
                  .filter((pet) => (filter ? pet.species === filter : true))
                  .map((pet) => (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={3}
                      key={pet.id}
                      display="flex"
                      justifyContent="start"
                      alignItems="start"
                    >
                      <Card
                        sx={{
                          borderRadius: 10,
                          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
                          width: "100%",
                        }}
                      >
                        {pet.species != "null" ? (
                          <CardMedia
                            sx={{
                              height: 200,
                              objectFit: "contain",
                            }}
                            image={
                              pet.species === "Dog"
                                ? Dog
                                : pet.species === "Cat"
                                ? Cat
                                : pet.species === "Rabbit"
                                ? Rabbit
                                : pet.species === "Lizard"
                                ? Lizard
                                : pet.species === "Hamster"
                                ? Hamster
                                : null
                            }
                            title={`${pet.name} head shot`}
                          />
                        ) : (
                          <CardContent>No image available</CardContent>
                        )}
                        <CardContent>
                          <Typography
                            gutterBottom
                            variant="h5"
                            component="div"
                            textAlign="left"
                          >
                            {pet.name}
                          </Typography>

                          <Typography
                            variant="body2"
                            color="text.secondary"
                            textAlign="left"
                          >
                            {pet.description}
                          </Typography>
                        </CardContent>

                        <CardActions style={{ justifyContent: "center" }}>
                          <Button
                            size="small"
                            component={Link}
                            to={`/petdetails/${pet.animal_ID}`}
                          >
                            Learn More
                          </Button>
                          <Box mx={1} />
                          <Button
                            size="small"
                            onClick={() => {
                              window.location.href = `mailto:${pet.parent}`;
                            }}
                          >
                            Contact Owner
                          </Button>
                        </CardActions>
                      </Card>
                    </Grid>
                  ))}
              </Grid>
            )}
          </Box>
        </Box>
      </Box>
      <Footer />
    </ThemeProvider>
  );
}