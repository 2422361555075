import * as React from "react";
import "../../css/home.css";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import AppNavBar from "../../components/navbar";
import Footer from "../../components/footer";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import PetsIcon from "@mui/icons-material/Pets";
import CssBaseline from "@mui/material/CssBaseline";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function updateProfile() {
  const navigate = useNavigate();

  const userToken2 = JSON.parse(localStorage.getItem("userToken"));
  const email = userToken2.email;

  const [setUsers] = useState([{}]);
  const [setDescription] = useState("");

  useEffect(() => {
    const fetchUser = async () => {
      const URL = `https://api.animal-aid.ca/api/person?email=${email}`;

      const data = await fetch(URL);

      const jsonData = await data.json();
      setUsers(Object.values(jsonData));
      setDescription(jsonData[0].description);
    };
    fetchUser();
  }, []);

  const initialValues = {
    email: email,
    first_name: "",
    last_name: "",
    description: "",
    profile_image: "",
    password: "",
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (e) => {
    const data = new FormData(e.currentTarget);
    const email = data.get("email");
    e.preventDefault();
    fetch(`https://api.animal-aid.ca/api/person?email=${email}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formValues),
    }).then((response) => response.json());
    setIsSubmit(true);
    navigate(`/person?email=${userToken2.email}`);
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      console.log(formValues);
    }
  }, [formErrors, formValues, isSubmit]);

  return (
    <div style={{ display: "auto" }}>
      <AppNavBar />

      <Grid
        container
        component="main"
        sx={{ height: "100vh", marginTop: "5vh" }}
      >
        <CssBaseline />
        <Grid component={Paper} square>
          <Box
            sx={{
              my: 7,
              mx: 60,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <PetsIcon />
            </Avatar>

            <Box
              component="form"
              method="PUT"
              action="/"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 1 }}
              style={{ marginTop: "30px" }}
            >
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    value={formValues.email}
                    onChange={handleChange}
                    autoComplete="email"
                    InputProps={{
                      readOnly: true,
                    }}
                    style={{ backgroundColor: "#e6e6e6" }}
                  />
                </Grid>

                <Grid item xs={4}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="password"
                    label="Password"
                    name="password"
                    type="password"
                    value={formValues.password}
                    onChange={handleChange}
                    autoComplete="password"
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="first_name"
                    label="First Name"
                    name="first_name"
                    value={formValues.first_name}
                    onChange={handleChange}
                    autoComplete="first_name"
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="last_name"
                    label="Last Name"
                    name="last_name"
                    value={formValues.last_name}
                    onChange={handleChange}
                    autoComplete="last_name"
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    multiline
                    rows={4}
                    name="description"
                    label="Describe Your Pet in Detail"
                    type="description"
                    id="description"
                    value={formValues.description}
                    onChange={handleChange}
                    autoComplete="current-password"
                  />
                </Grid>
              </Grid>
              <Grid sx={{ display: "flex", gap: 30 }} item xs={12}>
                <Grid item xs={4}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    href={`/person?email=${userToken2.email}`}
                    style={{ margin: "20px 0px 15px" }}
                  >
                    Go Back To Profile
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    style={{ margin: "20px 0px 15px" }}
                  >
                    Update Information
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Grid sx={{ paddingTop: "70rem" }}>
        <Footer />
      </Grid>
    </div>
  );
}
