import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function CartIcon() {
  const [cartItems, setCartItems] = useState([]);

  useEffect(() => {
    // Get cart items from localStorage when the component mounts
    const storedCartItems = JSON.parse(localStorage.getItem('cartItems'));
    if (storedCartItems) {
      setCartItems(storedCartItems);
    }
  }, []);

  useEffect(() => {
    // Update cart items when localStorage changes
    function handleStorageChange() {
      const storedCartItems = JSON.parse(localStorage.getItem('cartItems'));
      if (storedCartItems) {
        setCartItems(storedCartItems);
      } else {
        setCartItems([]);
      }
    }
    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return (
    <div>
      <Link to="/cart">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
        >
          <path d="M2 2h2l2.431 13.91a2 2 0 0 0 1.977 1.636h11.184a2 2 0 0 0 1.977-1.636L22 2H2zm9 16a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm-6-8h12" />
          {cartItems.length > 0 && (
            <text
              x="16"
              y="10"
              fontSize="10"
              textAnchor="middle"
              fill="#fff"
              dominantBaseline="middle"
            >
              {cartItems.length}
            </text>
          )}
        </svg>
      </Link>
    </div>
  );
}

export default CartIcon;