import * as React from "react";
import "../../css/home.css";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import AppNavBar from "../../components/navbar";
import Footer from "../../components/footer";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import { useEffect, useState } from "react";

export default function userProfile() {
  const userToken2 = JSON.parse(localStorage.getItem("userToken"));
  const email = userToken2.email;

  const [users, setUsers] = useState([{}]);
  const [setDescription] = useState("");

  useEffect(() => {
    const fetchUser = async () => {
      const URL = `https://api.animal-aid.ca/api/person?email=${email}`;

      const data = await fetch(URL);

      const jsonData = await data.json();
      setUsers(Object.values(jsonData));
      setDescription(jsonData[0].description);
    };
    fetchUser();
  }, []);

  var firstLetter = email[0];
  const [tabIndex, setTabIndex] = useState(0);

  return (
    <div style={{ display: "auto" }}>
      <AppNavBar />

      <Box
        xs={12}
        sx={{
          height: "28rem",
          backgroundPositionY: "32px",
          backgroundImage: "url(https://source.unsplash.com/random/?animal)",
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Container maxWidth="fixed">
          <Grid sx={{ paddingTop: "20rem", paddingLeft: "2rem" }}>
            <Avatar sx={{ width: "15rem", height: "15rem" }}>
              <Typography
                variant="h1"
                fontWeight={"bold"}
                sx={{ paddingTop: "1rem" }}
                gutterBottom
              >
                {firstLetter}
              </Typography>
            </Avatar>
          </Grid>
        </Container>
      </Box>

      <Container maxWidth="fixed">
        <Container maxWidth="fixed">
          <Grid sx={{ display: "block" }}>
            <Grid
              sx={{ float: "left", paddingTop: "10rem", fontWeight: "bold" }}
            >
              <Typography
                variant="h2"
                fontWeight={"bold"}
                sx={{ color: "#00C1EB" }}
                gutterBottom
              >
                Name:{" "}
                {users.map((user) => (
                  <span key={user.email}>
                    {user.first_name} {user.last_name}
                  </span>
                ))}
              </Typography>
            </Grid>
          </Grid>
        </Container>

        <Container maxWidth="fixed">
          <Grid sx={{ paddingTop: "12.7rem" }}>
            <Divider />
          </Grid>
        </Container>

        <Container maxWidth="fixed" sx={{ paddingTop: "5rem" }}>
          <Box>
            <Grid sx={{ float: "left" }}>
              <Typography
                variant="h5"
                fontWeight={"bold"}
                sx={{ color: "#000000", marginTop: "1.5rem" }}
              >
                User Information <Button variant="contained" href={`/update/person?email=${userToken2.email}`} sx={{}}>Edit Profile</Button>
              </Typography>
            </Grid>
          </Box>
        </Container>

        <Container maxWidth="fixed">
          <Grid sx={{ paddingTop: "5rem" }}>
            <Box
              sx={{ bgcolor: "#00C1EB", maxWidth: "100%", height: "1rem" }}
            />
            <Box sx={{ bgcolor: "#f5f5f5", maxWidth: "100%", height: "20rem" }}>
              <Tabs
                value={tabIndex}
                onChange={(event, newValue) => setTabIndex(newValue)}
                indicatorColor="primary"
                textColor="primary"
                sx={{ justifyContent: "flex-start" }} // Add this line
              >
                <Tab label="User Information" />
              </Tabs>

              {tabIndex === 0 && (
                <Container
                  maxWidth="fixed"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <Grid sx={{ paddingTop: "0rem" }}>
                    {users.length > 0 && (
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell>First Name:</TableCell>
                            <TableCell>{users[0].first_name}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Last Name:</TableCell>
                            <TableCell>{users[0].last_name}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Email:</TableCell>
                            <TableCell>{users[0].email}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Profile Image:</TableCell>
                            <TableCell>
                              {"N/A"}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Description:</TableCell>
                            <TableCell>
                              {users[0].description
                                ? users[0].description
                                : "N/A"}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    )}
                  </Grid>
                </Container>
              )}
            </Box>
          </Grid>
        </Container>
      </Container>

      <Grid sx={{ paddingTop: "10rem" }}>
        <Footer />
      </Grid>
    </div>
  );
}
