import * as React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Container,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import AppNavBar from "../../components/navbar";
import Footer from "../../components/footer";
import backgroundImage from "../../images/dog-products.jpg";

const theme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#00C1EB",
    },
    secondary: {
      main: "#00C1EB",
      contrastText: "#00C1EB",
      dark: "#000000",
    },
    background: {
      default: "#ffffff",
    },
    divider: "rgba(0,0,0,0.12)",
    info: {
      main: "#f44336",
    },
  },
});

export default function ShoppingCart() {
    const [cartItems, setCartItems] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);

  useEffect(() => {
    // Get cart items from localStorage when the component mounts
    const storedCartItems = JSON.parse(localStorage.getItem('cartItems'));
    if (storedCartItems) {
      setCartItems(storedCartItems);
    }
  }, []);

  useEffect(() => {
    // Update cart items when localStorage changes
    function handleStorageChange() {
      const storedCartItems = JSON.parse(localStorage.getItem('cartItems'));
      if (storedCartItems) {
        setCartItems(storedCartItems);
      } else {
        setCartItems([]);
      }
    }

    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  useEffect(() => {
    // Compute total amount when cart items change
    const amount = cartItems.reduce((acc, item) => acc + item.price * item.quantity, 0);
    setTotalAmount(amount);
  }, [cartItems]);

  function handleIncrement(item) {
    const updatedItems = cartItems.map(i => {
      if (i.product_ID === item.product_ID) {
        return {...i, quantity: i.quantity + 1};
      }
      return i;
    });
    localStorage.setItem('cartItems', JSON.stringify(updatedItems));
    setCartItems(updatedItems);
  }

  function handleDecrement(item) {
    const updatedItems = cartItems.map(i => {
      if (i.product_ID === item.product_ID) {
        const newQuantity = i.quantity > 1 ? i.quantity - 1 : 1;
        return {...i, quantity: newQuantity};
      }
      return i;
    });
    localStorage.setItem('cartItems', JSON.stringify(updatedItems));
    setCartItems(updatedItems);
  }

  function handleRemove(item) {
    const updatedItems = cartItems.filter(i => i.product_ID !== item.product_ID);
    localStorage.setItem('cartItems', JSON.stringify(updatedItems));
    setCartItems(updatedItems);
  }

  function handleCheckout() {
    // Do something with the cart items
    console.log('Checkout:', cartItems);
    // Clear the cart
    localStorage.removeItem('cartItems');
    setCartItems([]);
  }


  return (
    <ThemeProvider theme={theme}>
      <AppNavBar />
      <Box
        sx={{
          bgcolor: "#cfe8fc",
          position: "relative",
          height: "20vh",
          backgroundImage: `url(${backgroundImage})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          borderBottomLeftRadius: 10,
          borderBottomRightRadius: 10,
          leftMargin: 10,
          rightMargin: 10,
          opacity: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 1,
        }}
      >
        <Container maxWidth="xl">
          <Typography variant="h1" textAlign="center" sx={{ color: "rgba(0, 0, 0, 0.7)" }}>
            Shopping Cart
          </Typography>
        </Container>
      </Box>

      <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Box sx={{ flexGrow: 1, paddingTop: "65px", position: "relative" }}>
          <Box sx={{ display: "flex", flexDirection: "column"}}>

            <div className={"cart-page"} style={{marginBottom: "100px", display: "flex", justifyContent: "center" }}>
              {cartItems.length > 0 ? (
                <div>

                  <table>
                    <thead>
                    <tr>
                      <th>Product</th>
                      <th>Description</th>
                      <th>Price</th>
                      <th>Quantity</th>
                      <th>Total Price</th>
                      <th>Remove</th>
                    </tr>
                    </thead>
                    <tbody>
                    {cartItems.map((item) => (
                      <tr key={item.product_ID}>
                        <td>{item.product_name}</td>
                        <td>{item.description}</td>
                        <td>${item.price}</td>
                        <td>
                          <p>
                            <button onClick={() => handleDecrement(item)}>-</button>
                            <span style={{padding: "5px"}}>{item.quantity}</span>
                            <button onClick={() => handleIncrement(item)}>+</button>
                          </p>
                        </td>
                        <td>${item.price * item.quantity}</td>
                        <td>
                          <button onClick={() => handleRemove(item)}>X</button>
                        </td>
                      </tr>
                    ))}
                    </tbody>
                  </table>
                  <p style={{fontSize: "1.2em"}}>Total Amount: ${totalAmount}</p>
                  <button className={"checkout"} onClick={handleCheckout}>
                    <Link  style={{textDecoration: "none", color: "white", fontSize: "1.5em"}} to="/confirmation">Checkout</Link>
                  </button>
                </div>
              ) : (
                <div>
                  <p style={{fontSize: "1.2em"}}>Your cart is empty.</p>
                  <button>
                    <Link style={{textDecoration: "none", color: "white", fontSize: "1.5em"}} to="/products">Continue shopping</Link>
                  </button>
                </div>
              )}
            </div>
          </Box>
        </Box>
      </Box>
      <Grid sx={{paddingTop: 20}}>
       <Footer />
      </Grid>
      
    </ThemeProvider>
  );
}
