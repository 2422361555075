import * as React from "react";
import '../../css/home.css';
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import AppNavBar from "../../components/navbar";
import Footer from "../../components/footer";
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardActionArea from '@mui/material/CardActionArea';
import { Link } from 'react-router-dom';
import PetVector from "../../images/petdogwhite.png";
import ProductVector from "../../images/productvector.png";
import Tooltip from '@mui/material/Tooltip';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';

export default function homePage() {
  const navigate = useNavigate();

  const handleSearch = (event) => {
    if (event.key === "Enter") {
      if ((event.target.value.toLowerCase() === "pet") || (event.target.value.toLowerCase() === "pets")) {
        navigate("/pets"); 
      } else if (event.target.value.toLowerCase() === "product" || event.target.value.toLowerCase() === "products") {
        navigate("/products");
      }
    }
  };

  return (
    <div display="auto">
      <AppNavBar/>

      <Box xs={12}
            sx={{
              height: "30vh",
              
              backgroundPositionY: "32px",
              backgroundImage: "url(https://source.unsplash.com/random/?animal)",
              backgroundRepeat: "no-repeat",
              backgroundColor: (t) =>
                t.palette.mode === "light"
                  ? t.palette.grey[50]
                  : t.palette.grey[900],
              backgroundSize: "cover",
              backgroundPosition: "center",
          }}>

      <Grid sx={{backgroundPosition: "center", padding: "8vh", fontWeight: 'bold'}}>
        <Typography variant="h1" fontWeight={"bold"} sx={{ color: "#00C1EB" }}>
          ANIMALAID P.S
        </Typography>
        <Typography variant="h6" fontWeight={"bold"} sx={{ color: "#FFFFFF" }} gutterBottom>
          Your All-In-One Pet Service
        </Typography>
      </Grid>

      </Box> 

      <Grid sx={{float: "left", paddingTop: "5vh", paddingLeft: "5vh", fontWeight: 'bold'}}>
          <Typography variant="h3" fontWeight={"bold"} sx={{ color: "#00C1EB" }} gutterBottom>
             Find your new best friend...
          </Typography>  
      </Grid>

   


      <Grid sx={{backgroundPosition: "center", padding: "5vh"}}>
            <TextField 
                      margin="normal"
                      required
                      fullWidth
                      id="search"
                      label="Search by Pet"
                      placeholder="Enter Pets, Products, or Something Specific"
                      name="search"
                      autoComplete="search"
                      focused
                      onKeyPress={handleSearch}
                    />
      </Grid>

      <Grid sx={{paddingTop: "0vh"}}>
          <Typography variant="h4" fontWeight={"bolder"} sx={{ color: "#000000" }} gutterBottom>
             Straight To Our Selections
          </Typography>  
      </Grid>

      <Grid sx={{paddingTop: "1vh", fontWeight: 'bold', display: "flex", flexDirection:"row", gap: "30px", justifyContent:"center"}}>
      <Card sx={{ ':hover': {boxShadow: 20, background: "#00C1EB", backgroundSize: 1 } }}>
          <CardActionArea sx={{height: "30vh", width: "30vh"}} component={Link} to="/pets">
          <Tooltip title="Pets" placement="left">
            <CardContent>
             <CardMedia
                     sx={{ height: 250 }}
                     image={PetVector}
              />
               </CardContent>
              </Tooltip>
           </CardActionArea>
        </Card>

        <Card sx={{ ':hover': {boxShadow: 20, background: "#00C1EB", backgroundSize: 1 } }}>
          <CardActionArea sx={{height: "30vh", width: "30vh"}} component={Link} to="/products">
          <Tooltip title="Products" placement="right">
             <CardContent>
             <CardMedia
                     sx={{ height: 250 }}
                     image={ProductVector}
              />
                <Typography></Typography>
             </CardContent>
             </Tooltip>
           </CardActionArea>
        </Card>
      </Grid>

      <Grid sx={{paddingTop: "10vh", paddingBottom: "15vh", fontWeight: 'bold'}}>
        <Container maxWidth="fixed">
        <Box sx={{ bgcolor: '#00C1EB', maxWidth: "100%", height: 10 }}/>
          <Box sx={{ bgcolor: '#f5f5f5', maxWidth: "100%", height: 300 }}>
             <Grid sx={{paddingTop: "1vh", paddingLeft:"2vh", float: "left", fontWeight: 'bold'}}>
             
             </Grid>
             <Box sx={{ flexGrow: 1 }}>
              <Grid display="flex" gap="150px" justifyContent="center"container spacing={2} minHeight={160}>
                
                  <Grid paddingTop="60px">
                    <Typography variant="h5" fontWeight={"bolder"}>Add Pet</Typography>
                    <Grid paddingTop="10px">
                      <Typography variant="body" fontWeight={"light"}>
                        If you wish to give your pet a new home,<br></br>
                        upload your pet to our website, and find a new home.<br></br>
                      </Typography>
                      <Grid paddingTop="10px">
                      <Button variant="contained" component={Link} to="/addpets">Add Pet</Button>
                      </Grid>
                    </Grid>
                  </Grid>
      
                  <Grid paddingTop="60px">
                  <Typography variant="h5" fontWeight={"bolder"}>Add Product</Typography>
                    <Grid paddingTop="10px">
                    <Typography variant="body" fontWeight={"light"}>
                        If you would like to take advantage of our online store,<br></br>
                        upload your pet-related product.<br></br>
                      </Typography>
                    <Grid paddingTop="10px"> 
                    <Button variant="contained" component={Link} to="/addproducts">Add Product</Button>
                    </Grid>
                    </Grid>
                  </Grid>

                  <Grid paddingTop="60px">
                    <Typography variant="h5" fontWeight={"bolder"}>Frequently Asked Questions</Typography>
                    <Grid paddingTop="10px">
                    <Typography variant="body" fontWeight={"light"}>
                        Having questions about pet-adoption is normal.<br></br>
                        Read about the frequently asked questions by clicking below.<br></br>
                      </Typography>
                    <Grid paddingTop="10px"> 
                    <Button variant="contained" component={Link} to="/FAQ">FAQ</Button>
                    </Grid>
                    </Grid>
                  </Grid>
            </Grid>
           </Box>
          </Box> 
        </Container>
      </Grid>

      <Footer/>
  </div>
  );
}
