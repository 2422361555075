import * as React from "react";
import "../../css/home.css";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import AppNavBar from "../../components/navbar";
import Footer from "../../components/footer";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import Container from "@mui/material/Container";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function FAQ() {
  return (
    <div display="auto">
      <AppNavBar />

      <Box
        xs={12}
        sx={{
          height: "30vh",

          backgroundPositionY: "32px",
          backgroundImage: "url(https://source.unsplash.com/random/?animal)",
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Grid
          sx={{
            backgroundPosition: "center",
            padding: "8vh",
            fontWeight: "bold",
          }}
        >
          <Typography
            variant="h1"
            fontWeight={"bold"}
            sx={{ color: "#00C1EB" }}
            gutterBottom
          >
            ANIMALAID P.S
            <Typography
              variant="h6"
              fontWeight={"bold"}
              sx={{ color: "#FFFFFF" }}
              paddingBottom="10px"
              gutterBottom
            >
              Frequently Asked Questions
            </Typography>
          </Typography>
        </Grid>
      </Box>

      <Container maxWidth="fixed">
        <Grid
          sx={{ paddingTop: "6vh", paddingLeft: "5vh", fontWeight: "bold" }}
        >
          <Grid>
            <Grid sx={{ float: "left" }}>
              <Typography
                variant="h4"
                fontWeight={"bolder"}
                sx={{ color: "#000000" }}
                gutterBottom
              >
                Have questions regarding AnimalAid?
              </Typography>
              <Grid sx={{ float: "left" }}>
                <Typography variant="h6" float="left">
                  Don&#39;t worry, it&#39;s completely normal. We&#39;re here to
                  answer them.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          sx={{
            paddingTop: "15vh",
            paddingBottom: "15vh",
            paddingLeft: "5vh",
            fontWeight: "bold",
            width: "1000px",
          }}
        >
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography fontWeight="bold">What is AnimalAid?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Thank you for choosing to use AnimalAid. AnimalAid is a project
                created by Andy Li, Colins Ogbonna, and Noel Yohannes of PRJs
                Team 10. This service is a pet-focused webservice that is
                designed to allow you, the user, place your pet up for adoption
                online and sell and/or purchase pet-related products. By using
                our service, you would be able to view detailed information
                about potential adoptees, so that you can add to your family in
                the click of a button. AnimalAid combines inquiry with
                e-commerce, allowing you, the user, to do everything you might
                need on our website in relation to pets.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography fontWeight="bold">
                How do I create an account?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Creating an account is the only way to take full advantage of
                everything that the AnimalAid Pet Service has the offer. In
                order to create an account you can‏‏‎ ‎
                <Typography component={Link} to="/signup">
                  click here
                </Typography>
                ‏‏‎ ‎or alternatively you can navigate to the top of the screen,
                and hit &#39;Sign Up&#39; on the navbar. After creating an
                account, you will have full access to each of the services that
                AnimalAid offers.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
              <Typography fontWeight="bold">
                How do I edit my account?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                In order to edit your account, make sure that you&#39;re
                succesfully logged into AnimalAid. Once you are, you can
                navigate to &#39;Edit Profile&#39; which can be found at top of
                the screen on the navigation bar.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography fontWeight="bold">
                How do I adopt a pet using AnimalAid?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                By navigating to the{" "}
                <Typography component={Link} to="/pets">
                  Pet Section
                </Typography>{" "}
                of AnimalAid, you will be able to view detailed information
                about each and every of pets that were submitted to AnimalAid.
                AnimalAid is upgraded regularly with new additions given to us
                by our own users. Once you find a pet that you would like to
                inquire about, you can click &#39;Contact Owner.&#39; It is
                important to us that all relinguishers act in accordance with
                the PAWS act of Canada, thus relinquishers will go through a
                two-week review process, which involves an interview.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
              <Typography fontWeight="bold">
                How do I purchase a product using AnimalAid?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                By navigating to the{" "}
                <Typography component={Link} to="/products">
                  Product Section
                </Typography>{" "}
                of AnimalAid, you will be able to view detailed information
                about each and every product that was submitted for sale on
                AnimalAid. This section functions as an e-commerce portion, so
                any user will be able to freely upload products for sale.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Container>
      <Footer />
    </div>
  );
}
