import * as React from "react";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { Button, Typography, Container, Box } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import AppNavBar from "../../components/navbar";
import "../../css/images.css";
import Food from "../../images/Food.jpg";
import Accessory from "../../images/Accessory.jpg";
import Furniture from "../../images/Furniture.jpg";
import Grooming from "../../images/Grooming.jpg";
import Health from "../../images/Health.jpg";

const theme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#00C1EB",
    },
    secondary: {
      main: "#00C1EB",
      contrastText: "#00C1EB",
      dark: "#000000",
    },
    background: {
      default: "#ffffff",
    },
    divider: "rgba(0,0,0,0.12)",
    info: {
      main: "#f44336",
    },
  },
});

export default function MediaCard() {
  const [productData, setProductData] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();
  const [isDeleting, setIsDeleting] = useState(false);
  const userToken = JSON.parse(localStorage.getItem("userToken"));

  const handleGoBackClick = () => {
    navigate("/products");
  };

  const [cartItems, setCartItems] = useState(
    JSON.parse(localStorage.getItem("cartItems")) || []
  );

  useEffect(() => {
    const fetchProductDetails = async () => {
      const data = await fetch(
        `https://api.animal-aid.ca/api/product?product_ID=${id}`
      );
      const jsonData = await data.json();
      setProductData(Object.values(jsonData));
    };
    fetchProductDetails();
  }, [id]);

  const addToCart = (product) => {
    const existingItem = cartItems.find(
      (item) => item.product_ID === product.product_ID
    );
    if (existingItem) {
      existingItem.quantity += 1;
    } else {
      const newItem = { ...product, quantity: 1 };
      setCartItems([...cartItems, newItem]);
    }

    localStorage.setItem("cartItems", JSON.stringify(cartItems));
  };

  const handleDeleteClick = async () => {
    setIsDeleting(true);

    try {
      const response = await fetch(
        `https://api.animal-aid.ca/api/product?product_ID=${id}`,
        {
          method: "DELETE",
        }
      );

      if (response.ok) {
        const message = "Product has been deleted";
        alert(message);
        window.location.href = "/products";
      } else {
        const { error } = await response.json();
        throw new Error(error);
      }
    } catch (error) {
      alert(`Failed to delete product: ${error.message}`);
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <AppNavBar />

      {productData[0] && (
        <div key={productData[0].product_ID}>
          <ArrowBackIcon
            variant="contained"
            onClick={handleGoBackClick}
            style={{
              marginLeft: "3%",
              float: "left",
              cursor: "pointer",
            }}
            fontSize="large"
          />
          <Box
            sx={{
              display: "flex",
              flexGrow: 1,
              paddingTop: "75px",
              marginLeft: "10%",
              marginTop: 10,
            }}
          >
            <Container maxWidth="sm" sx={{ position: "relative" }}>
              <Box
                sx={{
                  bgcolor: "red",
                  height: "49vh",
                  width: "50vh",
                  maxWidth: "50vh",
                  borderRadius: 10,
                  position: "absolute",
                  left: "2%",
                  top: "5%",
                  zIndex: 0,
                }}
              />
              {productData[0].product_type === "Food" ? (
                <Box
                  sx={{
                    backgroundImage: `url(${Food})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    bgcolor: "#cfe8fc",
                    height: "50vh",
                    width: "50vh",
                    maxWidth: "50vh",
                    borderRadius: 10,
                    position: "absolute",
                    zIndex: 1,
                  }}
                />
              ) : productData[0].product_type === "Accessory" ? (
                <Box
                  sx={{
                    backgroundImage: `url(${Accessory})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    bgcolor: "#cfe8fc",
                    height: "50vh",
                    width: "50vh",
                    maxWidth: "50vh",
                    borderRadius: 10,
                    position: "absolute",
                    zIndex: 1,
                  }}
                />
              ) : productData[0].product_type === "Furniture" ? (
                <Box
                  sx={{
                    backgroundImage: `url(${Furniture})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    bgcolor: "#cfe8fc",
                    height: "50vh",
                    width: "50vh",
                    maxWidth: "50vh",
                    borderRadius: 10,
                    position: "absolute",
                    zIndex: 1,
                  }}
                />
              ) : productData[0].product_type === "Grooming" ? (
                <Box
                  sx={{
                    backgroundImage: `url(${Grooming})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    bgcolor: "#cfe8fc",
                    height: "50vh",
                    width: "50vh",
                    maxWidth: "50vh",
                    borderRadius: 10,
                    position: "absolute",
                    zIndex: 1,
                  }}
                />
              ) : productData[0].product_type === "Health" ? (
                <Box
                  sx={{
                    backgroundImage: `url(${Health})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    bgcolor: "#cfe8fc",
                    height: "50vh",
                    width: "50vh",
                    maxWidth: "50vh",
                    borderRadius: 10,
                    position: "absolute",
                    zIndex: 1,
                  }}
                />
              ) : (
                <Box
                  sx={{
                    backgroundImage: `url(${productData[0].image})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    bgcolor: "#cfe8fc",
                    height: "50vh",
                    width: "50vh",
                    maxWidth: "50vh",
                    borderRadius: 10,
                    position: "absolute",
                    zIndex: 1,
                  }}
                />
              )}
            </Container>

            <Container sx={{ position: "relative" }}>
              <Box sx={{ textAlign: "left" }}>
                <Typography
                  variant="h5"
                  fontWeight={"bold"}
                  sx={{ color: "red", textDecoration: "underline" }}
                >
                  {productData[0].product_name}
                </Typography>
              </Box>

              <Box
                sx={{
                  textAlign: "left",
                  paddingTop: "30px",
                  marginRight: 20,
                }}
              >
                <Typography variant="h6" fontWeight={"bold"}>
                  Description
                </Typography>

                <Typography variant="body1" sx={{ marginTop: 1 }} gutterBottom>
                  {productData[0].description}
                </Typography>

                <Box sx={{ display: "flex", marginTop: 5 }}>
                  <Box sx={{ display: "inline-block" }}>
                    <Typography variant="h6" fontWeight={"bold"}>
                      Product Type
                    </Typography>

                    <Typography variant="body1" gutterBottom>
                      {productData[0].product_type}
                    </Typography>

                    <Typography
                      variant="h6"
                      fontWeight={"bold"}
                      sx={{ marginTop: 2 }}
                    >
                      Product Price
                    </Typography>

                    <Typography variant="body1" gutterBottom>
                      ${productData[0].price}
                    </Typography>

                    <Typography
                      variant="h6"
                      fontWeight={"bold"}
                      sx={{ marginTop: 2 }}
                    >
                      Seller
                    </Typography>

                    <Typography
                      variant="body1"
                      gutterBottom
                      sx={{textDecoration: "underline", cursor:"pointer"}} 
                      onClick={() => {
                        window.location.href = `mailto:${productData.seller}`;
                      }}
                    >
                      {productData[0].seller}
                    </Typography>
                  </Box>

                  <Box sx={{ display: "inline-block", paddingLeft: "100px" }}>
                    <Typography variant="h6" fontWeight={"bold"}>
                      Maker
                    </Typography>

                    <Typography
                      variant="body1"
                      gutterBottom
                      sx={{ textDecoration: "none" }}
                    >
                      {productData[0].maker}
                    </Typography>

                    <Typography
                      variant="h6"
                      fontWeight={"bold"}
                      sx={{ marginTop: 2 }}
                    >
                      Width/Length/Height (in.)
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      {productData[0].width_inches}/
                      {productData[0].length_inches}/
                      {productData[0].height_inches}.
                    </Typography>

                    <Typography
                      variant="h6"
                      fontWeight={"bold"}
                      sx={{ marginTop: 2 }}
                    >
                      Weight (lbs)
                    </Typography>

                    <Typography variant="body1" gutterBottom>
                      {productData[0].weight_lbs}
                    </Typography>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "50px"
                  }}
                >
                  {userToken && userToken.email ? (
                    <Button
                      variant="contained"
                      onClick={() => addToCart(productData[0])}
                      size="small"
                      sx={{marginRight: "5%"}}
                    >
                      Add to Cart
                    </Button>
                  ) : (
                    <Typography></Typography>
                  )}
                  {userToken && userToken.email ? (
                    userToken.email === productData[0].seller ? (
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={handleDeleteClick}
                        disabled={isDeleting}
                      >
                        {isDeleting ? "Deleting..." : "Delete Product"}
                      </Button>
                    ) : (
                      <Typography></Typography>
                    )
                  ) : null}
                </Box>
              </Box>
            </Container>
          </Box>
        </div>
      )}
    </ThemeProvider>
  );
}
