import React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import PetsIcon from '@mui/icons-material/Pets';
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import AppNavBar from "../../components/navbar";
import MenuItem from '@mui/material/MenuItem';
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';


const theme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#00C1EB",
    },
    secondary: {
      main: "#00C1EB",
      contrastText: "#00C1EB",
      dark: "#000000",
    },
    background: {
      default: "#ffffff",
    },
    divider: "rgba(0,0,0,0.12)",
    info: {
      main: "#f44336",
    },
  },
});

const productTypes = [
  {
    value: "Food",
    label: "Food"
  },
  {
    value: "Accessory",
    label: "Accessory"
  },
  {
    value: "Furniture",
    label: "Furniture"
  },
  {
    value: "Grooming",
    label: "Grooming"
  },
  {
    value: "Health",
    label: "Health"
  }
];

export default function AddAProduct() {
  const navigate = useNavigate();

  const userToken = localStorage.getItem("userToken");
  const userToken2 = JSON.parse(localStorage.getItem("userToken"));

  let emailVar; 
  if (userToken2 && userToken2.email) {
    emailVar = userToken2.email;
  }


  const initialValues = {product_name: "", description: "", price: "", image_01: "", width_inches: "", length_inches: "", height_inches: "", weight_lbs: "", maker: "", seller: emailVar, product_type: "", };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  
  const handleChange = (e) => {
    const {name, value} = e.target;
    setFormValues ({...formValues, [name]: value})
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    fetch(`https://api.animal-aid.ca/api/product`, {
      method: "POST",
      headers: {
        "Content-Type" : "application/json"
      },
      body : JSON.stringify(formValues)
    })
    .then(response=>response.json())
    setIsSubmit(true);
    navigate("/"); //comment out when testing form
  }

  useEffect(()=> {
    if(Object.keys(formErrors).length === 0 && isSubmit) {
      console.log(formValues);
    }
  },[formErrors, formValues, isSubmit])


  return (
    <ThemeProvider theme={theme}>
      <AppNavBar />

      <Grid
        container
        component="main"
        sx={{ height: "100vh", marginTop: "5vh" }}
      >
        <CssBaseline />
        <Grid component={Paper} square>
          <Box
            sx={{
              my: 7,
              mx: 50,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <PetsIcon />
            </Avatar>

            {/* Grey Colored Lock Icon */}
            {/* <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar> */}

            <Typography component="h1" variant="h5">
              Add a Product
            </Typography>
            <Typography component="body">
              Upload a product that you would like to sell.
            </Typography>

            <Box
              component="form"
              method="POST"
              action="/"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 1 }}
              style={{ marginTop: "20px" }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={7}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="product_name"
                    label="Product's Name"
                    name="product_name"
                    value={formValues.product_name}
                    onChange={handleChange}
                    autoComplete="product_name"
                  />
                </Grid>

                <Grid item xs={12} sm={2.5}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    select
                    id="product_type"
                    label="Product Type"
                    name="product_type"
                    value={formValues.product_type}
                    onChange={handleChange}
                    autoComplete="product_type"
                  >
                    {productTypes.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item xs={12} sm={2.5}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="price"
                    label="Products Price"
                    name="price"
                    value={formValues.price}
                    onChange={handleChange}
                    autoComplete="price"
                  />
                </Grid>

                <Grid item xs={2}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    label="Width (in.)"
                    id="width_inches"
                    name="width_inches"
                    value={formValues.width_inches}
                    onChange={handleChange}
                    autoComplete="width_inches"
                  />
                </Grid>

                <Grid item xs={12} sm={2}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="length_inches"
                    label="Length (in.)"
                    name="length_inches"
                    value={formValues.length_inches}
                    onChange={handleChange}
                    autoComplete="length_inches"
                  ></TextField>
                </Grid>

                <Grid item xs={2}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="height_inches"
                    label="Height (in.)"
                    name="height_inches"
                    value={formValues.height_inches}
                    onChange={handleChange}
                    autoComplete="height_inches"
                  />
                </Grid>

                <Grid item xs={3}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="maker"
                    label="Product Maker"
                    name="maker"
                    value={formValues.maker}
                    onChange={handleChange}
                    autoComplete="maker"
                  />
                </Grid>

                {!userToken && (
                  <Grid item xs={3}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="seller"
                      label="Seller"
                      name="seller"
                      value={formValues.seller}
                      onChange={handleChange}
                      autoComplete="seller"
                    />
                  </Grid>
                )}

                {userToken && userToken.length > 0 && (
                  <Grid item xs={3}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="seller"
                      label="Seller"
                      name="seller"
                      value={formValues.seller}
                      onChange={handleChange}
                      autoComplete="seller"
                      InputProps={{
                        readOnly: true,
                      }}
                      style={{ backgroundColor: "#e6e6e6" }}
                    />
                  </Grid>
                )}

                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    multiline
                    rows={5}
                    name="description"
                    label="Description of Product"
                    type="description"
                    id="description"
                    value={formValues.description}
                    onChange={handleChange}
                    autoComplete="description"
                  />
                </Grid>

                <Grid
                  item
                  xs={1}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <h4>Image</h4>
                </Grid>

                <Grid item xs={5}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    type="file"
                    id="image_01"
                    name="image_01"
                    value={formValues.image}
                    onChange={handleChange}
                    autoComplete="image"
                  />
                </Grid>
              </Grid>

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                style={{ margin: "20px 0px 15px" }}
              >
                Upload Product
              </Button>

              <Grid container justify="flex-center">
                <Grid item>
                  <Typography>Review can take up to Two Weeks.</Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}