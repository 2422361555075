import * as React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Pets";
import { Typography } from "@mui/material";

const FooterContainer = styled("footer")(() => ({
  backgroundColor: "#01C1EB", // Replace with your desired color
  // padding: theme.spacing(6),
  textAlign: "center",
}));

const BottomContainer = styled("footer")(() => ({
  bottom: 0,
  width: "100%",
  backgroundColor: "#0a0d0e", // Replace with your desired color
  color: "white",
  textAlign: "center",
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const userToken = localStorage.getItem("userToken");

function Footer() {
  return (
    <FooterContainer sx={{ position: "sticky"}}>
      <IconButton
        style={{ marginTop: 15 }}
        href="/"
        color="inherit"
        aria-label="menu"
      >
        <MenuIcon />
      </IconButton>
      <Box sx={{ flexGrow: 1 }} padding={6} style={{ marginTop: -25 }}>
        <Grid container spacing={20}>
          {!userToken && (
            <Grid item xs>
              <Typography
                variant="h5"
                fontWeight={"bold"}
                sx={{ color: "black", marginBottom: 2 }}
              >
                ANIMALAID P.S
              </Typography>
              <Box
                sx={{
                  bgcolor: "#FFFFFF",
                  height: 40,
                  border: 0.5,
                  borderColor: "#000000",
                }}
              >
                <Button color="inherit" href="/signup">
                  SIGN-UP
                </Button>
              </Box>
              <Grid paddingTop="10px">
                <Box
                  sx={{
                    bgcolor: "#FFFFFF",
                    height: 40,
                    border: 0.5,
                    borderColor: "#000000",
                  }}
                >
                  <Button color="inherit" href="/signin">
                    SIGN-IN
                  </Button>
                </Box>
              </Grid>
            </Grid>
          )}

          <Grid item xs>
            <Item>RESOURCES</Item>
            <Grid item xs={12}>
              {location.pathname != "/signup" && (
                <Button color="inherit" href="/FAQ">
                  FAQ
                </Button>
              )}
            </Grid>
            <Grid item xs={12}>
              {location.pathname != "/pets" && (
                <Button color="inherit" href="/signin">
                  Articles
                </Button>
              )}
            </Grid>
            <Grid item xs={12}>
              {location.pathname != "/pets" && (
                <Button color="inherit" href="/signin">
                  Must-Knows
                </Button>
              )}
            </Grid>
          </Grid>

          <Grid item xs>
            <Item>ABOUT</Item>
            <Grid item xs={12}>
              <Button
                color="inherit"
                onClick={() => {
                  window.location.href = `mailto: animal-aid@gmail.com`;
                }}
              >
                Contact Us
              </Button>
            </Grid>
            <Grid item xs={12}>
              {location.pathname != "/pets" && (
                <Button color="inherit" href="/signin">
                  Animal-Aid Instagram
                </Button>
              )}
            </Grid>
            <Grid item xs={12}>
              {location.pathname != "/pets" && (
                <Button color="inherit" href="/signin">
                  Animal-Aid Facebook
                </Button>
              )}
            </Grid>
          </Grid>

          <Grid item xs>
            <Item>BROWSE</Item>
            <Grid item xs={12}>
              {location.pathname != "/signup" && (
                <Button color="inherit" href="/pets">
                  Pets
                </Button>
              )}
            </Grid>
            <Grid item xs={12}>
              {location.pathname != "/pets" && (
                <Button color="inherit" href="/products">
                  Products
                </Button>
              )}
            </Grid>
            <Grid item xs={12}>
              {location.pathname != "/pets" && (
                <Button color="inherit" href="/signin">
                  Crowdfunds
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>

      {/* Wrap the IconButton and the copyright text in a container */}
      <BottomContainer>
        <Typography
          variant="subtitle1"
          align="center"
          color="white"
          component="p"
        >
          Copyright &copy; {new Date().getFullYear()} AnimalAid Pet Service. All
          rights reserved.
        </Typography>
      </BottomContainer>
    </FooterContainer>
  );
}

export default Footer;
